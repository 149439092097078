<template>
  <Layout>
    <PageHeader :title="title" moda_title="Datos de la Api" ref="pageForm">
      <template>
        <PageForm :typeform="typeform" @closeModa="closeModa" />
      </template>
    </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.show') }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option
                        v-for="(op, index) in pageOptions"
                        :key="index"
                        :label="op"
                        :value="op"
                        >{{ op }}</vs-option
                      >
                    </vs-select>
                    &nbsp;{{ $t('filter.entries') }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.search') }}:
                    <vs-input
                      v-model="filter"
                      type="search"
                      primary
                      class="shadow-lg"
                    ></vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="makeFields()"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template #cell(updatedAt)="data">
                  {{ moment.utc(data.item.updatedAt).format('DD-MM-YYYY') }}
                </template>
                <!-- Column: Actions -->
                <template
                  #cell(actions)="data"
                  class="d-flex align-items-center position-relative"
                >
                  <div
                    style="transform: translateY(-8px); z-index: 9999"
                    class="position-absolute"
                  >
                    <b-dropdown variant="link" no-caret>
                      <template #button-content>
                        <i class="fa fa-fw fa-bars font-size-16" />
                      </template>

                      <div>
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="uil-edit m-2 font-size-18" />
                          <span class="align-middle ml-5">Editar</span>
                        </b-dropdown-item>
                      </div>

                      <div>
                        <b-dropdown-item
                          @click="remove(data.item._id, data.item.name)"
                        >
                          <i class="mdi mdi-delete-outline m-2 font-size-18" />
                          <span class="align-middle ml-5">Eliminar</span>
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';

import PageForm from './form.vue';
import Swal from 'sweetalert2';
import { ADMIN_ROLE } from '../../../helpers/CONSTANTS';

export default {
  data() {
    return {
      apis: '',
      title: 'Exchange Api',
      typeform: {
        name: '',
        url: '',
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      tableData: [],

      filter: '',
      userData: '',
      userRole: '',
    };
  },
  components: { Layout, PageHeader, PageForm },
  methods: {
    makeFields() {
      const globalFields = [
        {
          key: 'name',
          sortable: true,
          label: 'Nombre'.toUpperCase(),
        },
        {
          key: 'url',
          sortable: true,
          label: 'URL'.toUpperCase(),
        },
        {
          key: 'updatedAt',
          sortable: true,
          label: 'Ultima actualizacion'.toUpperCase(),
        },
      ];

      const adminFields = [
        {
          key: 'actions',
          sortable: false,
          label: 'Acciones'.toUpperCase(),
        },
      ];

      if ([ADMIN_ROLE, 'ADMIN_OPERATOR'].includes(this.userRole)) {
        const fields = [...globalFields, ...adminFields];

        return fields;
      }

      return globalFields;
    },
    getUserRole() {
      this.userData = this.$store.getters['user/getUserData'];
      this.userRole = this.userData.role.name;
    },
    closeModa() {
      this.$refs.pageForm.modal = false;
      this.typeform = {
        name: '',
        url: '',
      };
      this.getData();
    },
    getData() {
      this.$http
        .get('/exchange-api')
        .then(({ data }) => {
          if (!data.apis) {
            return console.log('No hay currencies', data.apis);
          }
          const availableApis = data.apis.filter((api) => api.status === true);
          this.tableData = availableApis;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit(item) {
      this.typeform = item;
      this.$refs.pageForm.modal = true;

      console.log(item);
    },
    remove(id, name) {
      if (!id) return;

      Swal.fire({
        title: `¿Desea eliminar "${name}"?`,
        showDenyButton: true,
        icon: 'info',
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          return this.$http
            .delete(`exchange-api/${id}`)
            .then(() => {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Api eliminada con exito',
                showConfirmButton: false,
                timer: 1500,
              });
              this.getData();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    this.getData();
    this.getUserRole();
  },
};
</script>
